import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
  buildQuery,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const ReceiptPOCReport = () => {
  const [receiptNo, setReceiptNo] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const [itemData, setItemData] = useState([]);
  const filterColumns: Array<String> = ['id','RecpNum','Qty','total'];
  const filterChange = (searchInput: any, newData = false) => {
    setTableFilter(searchInput);
    let available: any = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };
  const columns = [
    {
      name: 'POC Id',
      selector: (row) => `${row.id}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Receipt Number',
      selector: (row) => `${row.RecpNum}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Qty',
      selector: (row) => `${row.Qty}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Total',
      selector: (row) => `${row.total}`,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const getApi = async () => {
    showLoader();
    let res = await axios.get(`${baseUri()}grey-receipt-advice/receipt-poc-report?receiptNum=${receiptNo}`);
    res = (await res.data.data?.result) || [];
    setTableData(res);
    setItemData(res);
    hideLoader();
  };

  return (
    <>
      <SEO title="Receipt No-POC Link" />
      <Card>
        <header>Receipt No-POC Link</header>
        <CardBody>
          <InputGroup fullWidth size="Small">
            <input
              type="text"
              name="receiptNumber"
              placeholder="Enter Receipt Number"
              value={receiptNo}
              onChange={(e) => setReceiptNo(e.target.value)}
              className="receiptNo require"
            />
          </InputGroup>
          <div
            style={{
              marginTop: '10px',
            }}
          >
            <Button
              status="Success"
              style={{
                marginRight: '10px',
              }}
              onClick={getApi}
            >
              Submit
            </Button>
            <Button status="Basic">Reset</Button>
          </div>
        </CardBody>
      </Card>
      <Card>
        <header>Receipt No-POC Link Report</header>
        <CardBody>
          <InputGroup fullWidth size="Small">
            <input
              type="text"
              onChange={(e) => filterChange(e.target.value)}
              placeholder="Filter"
              name="tableFilter"
              value={tableFilter}
            />
          </InputGroup>
          <DataTable
            columns={columns}
            data={tableData}
            fixedHeader
            allowOverflow={false}
            overflowY={false}
            overflowX={false}
            wrap={false}
            highlightOnHover={true}
            pagination={true}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default ReceiptPOCReport;
